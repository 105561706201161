import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import Logo from "../../static/images/logo.png"
import FaceBook from "../../static/images/fb.png"

function bgHeader(){
  var scrolled = window.pageYOffset
  var height = window.innerHeight
  return scrolled - height;
}

const Header = ({ siteTitle }) => {
  const [bgStatus, setbgStatus] = useState('');

  useEffect(
    () => {
      window.addEventListener('scroll', function ( event ) {
        var scrolled = bgHeader()
        if(scrolled < 0){
          setbgStatus('')
        }  else if( scrolled > 0 && scrolled < 100){
          setbgStatus(' has-background-white')
        }  else{ }
    });  
    }
  )
  
  return(
  <header className={'section' + bgStatus} id='headercustom' style={{position: 'fixed', width: '100%', zIndex: '23'}}>
    <div className='container'>
      <div className = 'columns is-vcentered'>
      <div className = 'column has-text-centered-mobile'>
        <Link to='/'><img src={Logo} alt='' style={{width: '160px', filter: 'invert(1)'}}></img></Link>
      </div>
      <div className = 'column has-text-centered'>
        <Link to='/about' className='is-size-5 is-size-4-mobile has-padding' style={{color: 'black'}}>about</Link>
        <Link to='/process' className='is-size-5 is-size-4-mobile has-padding' style={{color: 'black'}}>process</Link>
        <Link to='/contact' className='is-size-5 is-size-4-mobile has-padding' style={{color: 'black'}}>contact</Link>
      </div>
      <div className = 'column has-text-right is-flex  is-hidden-mobile ' style={{justifyContent: 'flex-end', alignItems: 'center'}}>
        <Link to='/' className='is-size-5 is-uppercase txt-blue is-block' style={{marginLeft: '2rem'}}>
          <img src={FaceBook} alt='' style={{width: '32px'}}></img>
        </Link>
      </div>
      </div>
    </div>
  </header>
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
